import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ImageTriplet = makeShortcode("ImageTriplet");
const ImagePair = makeShortcode("ImagePair");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1709706576/Photography/RH%20Res%20%2723/RHRes-Ashu-59_thd5mw.jpg"
    }}></img>
    <h2>{`Robot Heart Residency`}</h2>
    <p>{`Nov 2023・Sony a7sii`}</p>
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1709706245/Photography/RH%20Res%20%2723/RHRes-Ashu-58_brakdy.jpg"
    }}></img>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688952/Photography/RH%20Res%20%2723/RHRes-Ashu-06_fqk8sl.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688943/Photography/RH%20Res%20%2723/RHRes-Ashu-02_q8gt0i.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688943/Photography/RH%20Res%20%2723/RHRes-Ashu-01_ffwnsh.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688946/Photography/RH%20Res%20%2723/RHRes-Ashu-04_yrvpfm.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688954/Photography/RH%20Res%20%2723/RHRes-Ashu-07_bups79.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688958/Photography/RH%20Res%20%2723/RHRes-Ashu-11_yjonfu.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688953/Photography/RH%20Res%20%2723/RHRes-Ashu-10_qbd0a4.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709707687/Photography/RH%20Res%20%2723/RHRes-Ashu-65_f7gfv8.jpg" src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688961/Photography/RH%20Res%20%2723/RHRes-Ashu-12_hod8cn.jpg" mdxType="ImageTriplet" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1709690030/Photography/RH%20Res%20%2723/RHRes-Ashu-53_hetg8k.jpg"
    }}></img>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709709660/Photography/RH%20Res%20%2723/RHRes-Ashu-14_ou8ccq.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709709659/Photography/RH%20Res%20%2723/RHRes-Ashu-13_vaxlaw.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709709682/Photography/RH%20Res%20%2723/RHRes-Ashu-17_of1pgp.jpg" mdxType="ImageTriplet" />
    <ImagePair src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688957/Photography/RH%20Res%20%2723/RHRes-Ashu-13_s0bf8f.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709710104/Photography/RH%20Res%20%2723/RHRes-Ashu-19_kerpza.jpg" mdxType="ImagePair" />
    <ImagePair src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688960/Photography/RH%20Res%20%2723/RHRes-Ashu-15_bcknz6.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688963/Photography/RH%20Res%20%2723/RHRes-Ashu-16_hb5rbr.jpg" mdxType="ImagePair" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688963/Photography/RH%20Res%20%2723/RHRes-Ashu-18_rohyem.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688966/Photography/RH%20Res%20%2723/RHRes-Ashu-19_s493pt.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688968/Photography/RH%20Res%20%2723/RHRes-Ashu-22_epz6fy.jpg" mdxType="ImageTriplet" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1709690025/Photography/RH%20Res%20%2723/RHRes-Ashu-49_yqzgke.jpg"
    }}></img>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688972/Photography/RH%20Res%20%2723/RHRes-Ashu-28_gbrmxv.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688971/Photography/RH%20Res%20%2723/RHRes-Ashu-27_f5ckwg.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688971/Photography/RH%20Res%20%2723/RHRes-Ashu-26_epddox.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688965/Photography/RH%20Res%20%2723/RHRes-Ashu-21_cbjvi5.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688963/Photography/RH%20Res%20%2723/RHRes-Ashu-20_i7asck.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688963/Photography/RH%20Res%20%2723/RHRes-Ashu-17_bwymwr.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688969/Photography/RH%20Res%20%2723/RHRes-Ashu-25_e2gzvd.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688969/Photography/RH%20Res%20%2723/RHRes-Ashu-23_yhxyqr.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688968/Photography/RH%20Res%20%2723/RHRes-Ashu-24_hzga8q.jpg" mdxType="ImageTriplet" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1709690030/Photography/RH%20Res%20%2723/RHRes-Ashu-50_gxhfbe.jpg"
    }}></img>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688974/Photography/RH%20Res%20%2723/RHRes-Ashu-31_xpsj0z.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688975/Photography/RH%20Res%20%2723/RHRes-Ashu-30_iitnor.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688973/Photography/RH%20Res%20%2723/RHRes-Ashu-29_hwfi40.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688977/Photography/RH%20Res%20%2723/RHRes-Ashu-33_c9b7x3.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688977/Photography/RH%20Res%20%2723/RHRes-Ashu-34_w8km2j.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688976/Photography/RH%20Res%20%2723/RHRes-Ashu-32_dy4nbr.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688981/Photography/RH%20Res%20%2723/RHRes-Ashu-37_zfadtg.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688979/Photography/RH%20Res%20%2723/RHRes-Ashu-36_cgt1jz.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709688978/Photography/RH%20Res%20%2723/RHRes-Ashu-35_ldwwa4.jpg" mdxType="ImageTriplet" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1709690027/Photography/RH%20Res%20%2723/RHRes-Ashu-51_exgkqf.jpg"
    }}></img>
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709707227/Photography/RH%20Res%20%2723/RHRes-Ashu-61_xzxu3c.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709707227/Photography/RH%20Res%20%2723/RHRes-Ashu-63_jhcqhh.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709707227/Photography/RH%20Res%20%2723/RHRes-Ashu-64_okd6cb.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688983/Photography/RH%20Res%20%2723/RHRes-Ashu-40_bmltv5.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688987/Photography/RH%20Res%20%2723/RHRes-Ashu-43_tcznzw.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709705466/Photography/RH%20Res%20%2723/RHRes-Ashu-41_lafh3g.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688990/Photography/RH%20Res%20%2723/RHRes-Ashu-45_svma7e.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688988/Photography/RH%20Res%20%2723/RHRes-Ashu-44_jhnbld.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709705467/Photography/RH%20Res%20%2723/RHRes-Ashu-42_acrrle.jpg" mdxType="ImageTriplet" />
    <ImageTriplet src1="https://res.cloudinary.com/studiocollective/image/upload/v1709688957/Photography/RH%20Res%20%2723/RHRes-Ashu-08_k5pcrq.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1709688956/Photography/RH%20Res%20%2723/RHRes-Ashu-09_valgph.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1709709660/Photography/RH%20Res%20%2723/RHRes-Ashu-15_knmtn7.jpg" mdxType="ImageTriplet" />
    <img {...{
      "wide": "true",
      "src": "https://res.cloudinary.com/studiocollective/image/upload/v1709690024/Photography/RH%20Res%20%2723/RHRes-Ashu-48_izzyyb.jpg"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      